import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Whispering Pines Mini Evleri Hakkında
			</title>
			<meta name={"description"} content={"Whispering Pines Mini Evleri, doğanın cazibesi ile modern yaşamın konforunu iç içe geçiren eşsiz bir inziva deneyimi sunmaktadır."} />
			<meta property={"og:title"} content={"Hakkımızda | Whispering Pines Mini Evleri Hakkında"} />
			<meta property={"og:description"} content={"Whispering Pines Mini Evleri, doğanın cazibesi ile modern yaşamın konforunu iç içe geçiren eşsiz bir inziva deneyimi sunmaktadır."} />
			<meta property={"og:image"} content={"https://kimbachi.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://kimbachi.live/img/a1.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					Hakkımızda
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					Whispering Pines Mini Evleri, doğanın cazibesi ile modern yaşamın konforunu iç içe geçiren eşsiz bir inziva deneyimi sunmaktadır. Konseptimiz, konukların hem tenha hem de lüks hissettiren bir ortamda gevşeyebilecekleri ve doğal dünyayla yeniden bağlantı kurabilecekleri sakin bir sığınak yaratma arzusundan doğmuştur.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
				>
					Bizim Felsefemiz
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Doğanın restore etme ve ilham verme gücüne inanıyoruz. Whispering Pines'ta, mini evlerimizin ve çevresindeki ortamın her yönü, doğa ile uyumlu bir denge sağlamak, rahatlama ve gençleşme için huzurlu bir sığınak sağlamak için özenle hazırlanmıştır.
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Gözlerden Uzak Ama Erişilebilir - Her mini ev, rahatlık ve güvenlik için kolayca erişilebilir kalırken mahremiyet ve inziva sunacak şekilde konumlandırılmıştır.
Eşsiz Mimari Tasarımlar - Mini evlerimiz sadece birer konaklama yeri değil, her biri kendi kişiliğine ve cazibesine sahip birer mimari mücevherdir.
Çevre Bilinci - Whispering Pines'ın merkezinde sürdürülebilirlik yer almaktadır. İnşaattan günlük operasyonlara kadar çevre dostu uygulamalara bağlıyız.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://kimbachi.live/img/a2.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://kimbachi.live/img/a3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://kimbachi.live/img/a4.jpg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					lg-text-align="center"
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Kişiselleştirilmiş Deneyimler - Misafirlerimizin deneyimleri, özel açık hava etkinliklerinden mini evlerdeki özel isteklere kadar kişiselleştirilmiştir.
Doğa ile Bağlantı - Doğal bir ortamın içine dalmış olan misafirlerimiz ormanın huzurunun, kuşların şarkılarının ve yıldızların güzelliğinin tadını çıkarabilirler.
				</Text>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});